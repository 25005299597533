<div class="content" *ngIf="locale === locales.ru">
  <div class="agreement-box panel">
    <h5>Политика конфиденциальности применяется и размещается на веб-сайтах:<br/>
      <a href="https://org.mtgame.ru" target="_blank">https://org.mtgame.ru</a>, <a href="https://stat.mtgame.ru" target="_blank">https://stat.mtgame.ru</a>, <a href="https://mtgame.ru">https://mtgame.ru</a>
    </h5>
    <p>
    Политика конфиденциальности - обработки персональных данных (далее – Политика) разработана в соответствии с Федеральным законом от 27.07.2006. №152-ФЗ «О персональных данных» (далее – ФЗ-152). Настоящая Политика определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных пользователей сайта с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну. Оператор обязан опубликовать или иным образом обеспечить неограниченный доступ к настоящей Политике обработки персональных данных в соответствии с ч. 2 ст. 18.1. ФЗ 152. Настоящая политика размещена на официальных веб-сайтах «Правообладателя», указанных выше в открытом доступе и предназначена для всех пользователей сайта. Пользуясь сайтом, пользователь, пользователь-организатор подтверждает, что ознакомился с настоящей политикой и полностью согласен со всеми ее условиями. Помимо терминов, изложенных в договоре, в настоящем документы используются следующие
    </p>

    <h5>Термины и определения</h5>
    <p>
    Оператор персональных данных – “Правообладатель”.
    </p>
    <p>
    «Пользователь», «Пользователь-организатор» - лица, использующие ресурсы, перечисленные в указанной Политике о конфиденциальности, в том числе закрытые части ресурсов (где доступ предоставляется по отдельному договору).
    </p>
    <p>
    Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
    </p>
    <p>
    Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
    </p>
    <h5>1.ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>
        1.1. Обработка персональных данных у Оператора осуществляется на основе следующих принципов:
        <ul>
          <li>− законности и справедливости;</li>
          <li>− ограничение обработки персональных данных достижением конкретных, заранее определенных и законных целей;</li>
          <li>− недопущение обработки персональных данных, несовместимой с целями сбора персональных данных;</li>
          <li>− недопущение объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</li>
          <li>− обработка только тех персональных данных, которые отвечают целям их обработки;</li>
          <li>− соответствие содержания и объема обрабатываемых персональных данных заявленным целям обработки;</li>
          <li>− недопущение обработки персональных данных, избыточных по отношению к заявленным целям их обработки;</li>
          <li>− обеспечение точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;</li>
          <li>− уничтожение либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом.</li>

        </ul>
      </li>
    </ul>

    <h5>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ:</h5>
    <ul>
      <li>2.1. Обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</li>
      <li>2.2. Оказание Оператором физическим и юридическим лицам услуг, связанных с хозяйственной деятельностью Оператора, включая контакты Оператора с такими лицами, в том числе по электронной почте, по телефону, по адресу, представленным соответствующим лицом.</li>
      <li>2.3. Направление консультаций, ответов обратившимся лицам с помощью средств связи и указанных ими контрактных данных.</li>
      <li>2.4. Продвижение товаров, работ, услуг Оператора на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи (при условии предварительного согласия субъекта персональных данных).</li>
    </ul>
    <h5>3.СОБИРАЕМЫЕ ДАННЫЕ</h5>
    <ul>
      <li>3.1. В целях оказания услуг Пользователю, Пользователю-организатору, а также в иных целях, указанных в Политике, «Правообладатель» может запрашивать следующие данные: фамилию, имя, отчество, электронный адрес, номер телефона, номер мобильного телефона, антропометрические данные. «Правообладатель» также регистрирует данные о покупке совершенной Пользователем, Пользователем-организатором. Пользователь, пользователь-организатор используя сайт, дает свое согласие на обработку указанных данных. Пользователь вправе в любое время отозвать данное согласие, направив письменно уведомление об отзыве по адресу info@mtgame.ru.</li>
      <li>3.2. В случае отзыва согласия на обработку персональных данных «Правообладатель» прекращает обработку и уничтожает персональные данные в течение пяти рабочих дней с момента получения отзыва. При этом после удаления персональных данных пользователя (пользователя-организатора), «Правообладатель» не оказывает ему более услуги и не направляет рассылку, а также не возмещает стоимость оплаченных и не полученных в связи с этим услуг.</li>
    </ul>
    <h5>4. ОБЕСПЕЧЕНИЕ ИСПОЛНИТЕЛЕМ ПРАВ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>4.1. Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и другими нормативно-правовыми актами, регламентирующими обработку персональных данных.</li>
      <li>4.2. «Правообладатель» обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
      <li>4.3. «Правообладатель» обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, по запросу.</li>
      <li>4.4. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.</li>
      <li>4.5. В случае представления интересов субъекта персональных данных представителем полномочия представителя подтверждаются доверенностью, оформленной в установленном порядке.</li>

      <li>4.6. В случаях предоставления субъектом персональных данных письменного согласия на использование персональных данных для такого согласия достаточно простой письменной формы.</li>
      <li>4.7. «Правообладатель» гарантирует безопасность и конфиденциальность используемых персональных данных.</li>
      <li>4.8. Обработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи допускается только при условии предварительного согласия субъекта персональных данных.</li>
    </ul>
    <h5>5. ПОЛУЧЕНИЕ, ОБРАБОТКА, ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>5.1. «Правообладатель» устанавливает следующий порядок получения персональных данных при регистрации и заказе услуг на сайте «Правообладателя»: субъект персональных данных указывает: Ф.И.О., телефон, эл.почту, дату рождения, мессенджеры и другую информацию, необходимую для идентификации.</li>
      <li>5.2. При обращении за получением услуг «Правообладателя» Пользователь, Пользователь-организатор указывает установленные соответствующими формами данные.</li>
      <li>5.3. «Правообладатель» не получает и не обрабатывает персональные данные клиента о его расовой принадлежности, политических взглядах, религиозных и философских убеждениях, состоянии здоровья, интимной жизни, если законом не предусмотрено иное.</li>
      <li>5.4.В случаях, непосредственно связанных с вопросами трудовых отношений, в соответствии со ст. 24 Конституции Российской Федерации «Правообладатель» вправе получать и обрабатывать данные о частной жизни клиента только с его письменного согласия.</li>
      <li>5.5. В случае принятия оферты, размещённой на сайте «Правообладателя», либо заключения другого договора с «Правообладателем», обработка персональных данных осуществляется для исполнения соответствующего договора, вступившего в силу вследствие принятия условий оферты либо заключения другого договора соответственно.</li>
      <li>5.6. «Правообладатель» вправе обрабатывать персональные данные пользователей -физических лиц только с их согласия на использование персональных данных.</li>
      <li>
        5.7. Согласие Заказчика на обработку персональных данных не требуется в следующих случаях:
        <ul>
          <li>5.7.1. Персональные данные являются общедоступными.</li>
          <li>5.7.2. Обработка персональных данных осуществляется на основании федерального закона, устанавливающего ее цель, условия получения персональных данных и круг субъектов, персональные данные которых подлежат обработке.</li>
          <li>5.7.3. По требованию полномочных государственных органов - в случаях, предусмотренных федеральным законом.</li>
          <li>5.7.4. Обработка персональных данных в целях исполнения договора, заключенного с «Правообладателем».</li>
          <li>5.7.5. Обработка персональных данных осуществляется для статистических или иных научных целей при условии обязательного обезличивания персональных данных.</li>
          <li>5.7.6. Обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов Пользователя, если получение его согласия невозможно.</li>

        </ul>
      </li>
      <li>
        5.8. «Правообладатель» обеспечивает безопасное хранение персональных данных, в том числе:
        <ul>
          <li>5.8.1. Хранение, комплектование, учет и использование содержащих персональные данные документов организуется в форме обособленного архива «Правообладателя».</li>

          <li>5.8.2. Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</li>
        </ul>
      </li>
    </ul>
    <h5>6. ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>6.1. Персональные данные передаются «Правообладателем» с соблюдением следующих требований:
        <ul>
          <li>6.1.1. запрещается сообщать персональные данные третьей стороне без письменного согласия клиента, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни, здоровью клиента, а также в других случаях, предусмотренных законами;</li>
          <li>6.1.2. не сообщать персональные данные в коммерческих целях без письменного согласия субъекта таких данных;</li>
          <li>6.1.3. предупредить лиц, получающих персональные данные, о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, и требовать от этих лиц подтверждения того, что это правило соблюдено;</li>
          <li>6.1.4. разрешать доступ к персональным данным только специально уполномоченным лицам, при этом указанные лица должны иметь право получать только те персональные данные, которые необходимы для выполнения конкретных функций;</li>
          <li>6.1.5. не запрашивать информацию о состоянии здоровья клиента, за исключением тех сведений, которые относятся к вопросу о возможности выполнения клиентом обязательств по договору с Оператором;</li>
          <li>6.1.6. передавать персональные данные клиента его представителям в порядке, установленном Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».</li>

        </ul>
      </li>
    </ul>
    <h5>7. ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</h5>
    <ul>
      <li>7.1. Право доступа к персональным данным имеют:
        <ul>
          <li>- руководитель «Правообладателя»;</li>
          <li>- работающие с определенным клиентом работники «Правообладателя»;</li>
          <li>- работники бухгалтерии;</li>
          <li>- работники, осуществляющие техническое обеспечение деятельности «Правообладателя».</li>
        </ul>
      </li>
      <li>7.2. Пользователи  в целях обеспечения защиты персональных данных имеют следующие права:
        <ul>
          <li>7.2.1. на полную информацию об их персональных данных и обработке этих данных;</li>
          <li>7.2.2. на свободный бесплатный доступ к своим персональным данным, включая право на получение копий любой записи, содержащей персональные данные, за исключением случаев, предусмотренных федеральным законом;</li>
          <li>7.2.3. на определение своих представителей для защиты своих персональных данных;</li>
          <li>7.2.4. на требование об исключении или исправлении неверных или неполных персональных данных, а также данных, обработанных с нарушением требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
        </ul>
      </li>
      <li>7.3. Копировать и делать выписки персональных данных разрешается исключительно в служебных целях с разрешения руководителя.</li>

    </ul>
    <h5>8. ИЗМЕНЕНИЕ НАСТОЯЩЕЙ ПОЛИТИКИ</h5>
    <ul>
      <li>8.1. «Правообладатель» вправе вносить изменения в Политику по своему усмотрению и без предварительного уведомления Пользователя сайта. Поэтому Пользователю Сайта рекомендуется при следующем посещении сайта заново перечитать условия и обратить внимание на возможные изменения или поправки. Новая редакция Политики вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики.</li>
    </ul>
    <h5>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h5>
    <ul>
      <li>9.1. Ни при каких обстоятельствах «Правообладатель» не несет ответственность перед пользователем или перед любыми третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, вызванный в связи с использованием сайта, содержимого сайта или иных материалов, к которым Пользователь, пользователь-организатор получили доступ с помощью сайта, даже если «Правообладатель» предупреждал или указывал на возможность такого вреда.</li>
      <li>9.2. «Правообладатель» не несет ответственности за потерю данных вследствие действий третьих лиц, в том числе хостинг-провайдера «Правообладателя», ошибок программного обеспечения, ненадежности каналов связи, а также незаконных действий хакеров и прочих злоумышленников. В случае обнаружения утери пользовательских данных «Правообладатель» обязуется уведомить пользователей в течение 24 часов с момента установления факта утери, а также приложить все возможные усилия для уменьшения негативных последствий для Пользователей и идентификации ответственных.</li>
      <li>9.3. Недействительность отдельных норм настоящей Политики конфиденциальности, если таковое будет признано решением суда или иного уполномоченного государственного органа, не влечет ее недействительности в целом.</li>
    </ul>
    <h5>10. ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ НОРМ, РЕГУЛИРУЮЩИХ ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h5>
    <ul>
      <li>10.1. Лица, виновные в нарушении порядка обращения с персональными данными, несут дисциплинарную, административную, гражданско-правовую или уголовную ответственность в соответствии с федеральными законами.</li>
    </ul>

  </div>
</div>

<div class="content" *ngIf="locale === locales.en">
  <div class="agreement-box panel">
    TODO
  </div>
</div>
