export const environment = {
  production: true,
  env: 'qa',
  sentry: 'https://6c4c140595ce46d89777a5817e0e63e3@sentry.io/1406697',
  centrifugoUrl: 'wss://ws2.mtgame.dev/connection/websocket',
  metrikaCounter: '',
  recaptchaKey: '6LfejroUAAAAANkkrnzijkqtV5C1f3v-7TMAVcyI',
  cdnHost: 'https://qa-cdn.mtgame.ru',
  service: 'public'
};
